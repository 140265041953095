<template>
    <div class="zp-fix-margin">
        <form method="POST" :action="url_login" v-on:submit="sendForm" class="zp-errors-border-bottom">
            <input type="hidden" name="_token" :value="csrf">

            <div class="zp-image-login-mshow">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
            </div>
            
            <div class="form-group">
                <input type="hidden" name="originTab" value="login">
                <input type="email" v-model="$v.formLogin.email.$model"
                    :class="!$v.formLogin.email.$anyError? 'form-control': 'form-control zp-form-error'"
                    name="email" placeholder="Email" autocomplete="email" autofocus required @keypress="keypress">
            </div>
            <div class="zp-error-data" v-if="$v.formLogin.email.$dirty">
                <span v-if="!$v.formLogin.email.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
                <span v-if="!$v.formLogin.email.email && $v.formLogin.email.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Email inválido
                </span>
            </div>
            <div class="form-group">
                <input v-model="$v.formLogin.password.$model" type="password"
                    :class="!$v.formLogin.password.$anyError ? 'form-control': 'form-control zp-form-error'"
                    name="password" placeholder="Contraseña" autocomplete="current-password" required
                    @keypress="keypress">
            </div>
            <div class="zp-error-data" v-if="$v.formLogin.password.$dirty">
                <span v-if="!$v.formLogin.password.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
            </div>
            <div class="form-group mt-4">
                <div class="form-check form-check-inline">

                    <div @click="formLogin.recordar = !formLogin.recordar" class="zp-inicio-sesion-checkbox">
                      <input v-model="formLogin.recordar" type="checkbox" id="recordarCheck" style="display: none;">
                      <i class="fas fa-check-square zp-texto-color-principal" v-show="formLogin.recordar" ></i>
                      <i class="far fa-square" v-show="!formLogin.recordar"></i> 
                      <label class="form-check-label login-modal-fp" for="recordarCheck" @click="formLogin.recordar = !formLogin.recordar">
                        Recordar
                      </label>
                    </div>   
                </div>
                <div class="form-check form-check-inline float-right">
                    <a class="login-modal-link" href="#" @click="showForgetSection">
                        ¿Olvidaste la contraseña?
                    </a>
                </div>
            </div>
            <div v-if="showErrorsBackend" class="zp-error-bag">
                <div v-for="error in inputs_errors">
                    <div class="zp-error-data">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        {{error[0]}}
                    </div>
                </div>
            </div>
            <button type="submit" class="btn bg-main-color general-btn"
                :disabled="$v.formLogin.$invalid">
                {{btnText}}
            </button>
        </form>
    </div>
</template>

<script>
    import { required, minLength, between, sameAs, email } from 'vuelidate/lib/validators'

    export default {
        name: "FormularioInicioSesion",
        props: [
            "url_login",
            "inputs_old",
            "inputs_errors",
        ],
        data: function() {
            return  {
                formLoading: false,
                showErrorsBackend:false,
                btnText:"Enviar",
                formLogin:{
                    email:"",
                    password:"",
                    recordar:false,
                },
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        validations: {
            formLogin:{
                email:{
                    email,
                    required,
                },
                recordar:{
                },
                password:{
                    required,
                },
            }
        },
        watch:{
        },
        mounted() {
            if(this.inputs_old.originTab){
                if(this.inputs_old.originTab == "login"){
                    let self = this;
                    Object.entries(this.inputs_old).forEach( function(valor, indice, array){
                         if(array[indice][0]){
                            if(array[indice][0] != "_token"){
                                self.formLogin[array[indice][0]] = array[indice][1];
                            }
                         }
                    });

                    $('#loginModal').modal('show');
                    this.showErrorsBackend = true;
                }
            }
            let self = this;
            document.addEventListener('evento-cerrar-modales', function (e) {
                self.$v.$reset();
                self.showErrorsBackend = false;
            }, false);
        },
        methods:{
            showForgetSection(){
                $('#loginModal').modal('hide');
                $("#passwordModal").modal('show');
            },
            keypress(){
                this.showErrorsBackend = false;
            },
            sendForm (event) {
                if(!this.formLoading){
                    this.formLoading = true;
                    this.btnText = "Enviando...";
                    return true;
                }
                event.preventDefault();
                return false;
            },
            onlyNumber ($event) {
               let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
               if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                  $event.preventDefault();
               }
            },
            onlyLetters ($event) {
               var regex = new RegExp("^[a-zA-Z ]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    }
</script>

<style>

</style>
