window.Vue = require('vue');

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.component('formulario-inicio-sesion', require('../../componentes/sitio/FormularioInicioSesion.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = false;

if (document.getElementById("vue-formulario-inicio")) {
    const formulario_inicio = new Vue({
        el: '#vue-formulario-inicio',
    });
}